import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'

import * as Common from '@rushplay/common'
import styled from '@emotion/styled'
import { useI18n } from '@rushplay/i18n'

import * as Session from './session'
import * as configuration from './configuration'
import * as Icons from './icons'
import { AnjouanSeal } from './anjouan-seal'
import { AppVersion } from './app-version'
import { Button } from './button'
import { ClientType } from './constants'
import { GamesProviderImages } from './games-provider-images'
import { HtmlContent } from './html-content'
import { PaymentProviderImages } from './payment-provider-images'
import { SocialMediaSection } from './social-media'
import { comm100 } from './support-chat-api'
import { useSafeUpdateQuery } from './use-safe-update-query'

const Scrollable = styled(Common.Box)`
  overflow-x: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`

const LinkWrapper = styled(ReactRouter.Link)`
  transition: opacity 75ms ease-in;

  &:hover,
  &:active {
    opacity: 0.9;
  }
`

const ExternalLinkWrapper = styled.a`
  transition: opacity 75ms ease-in;

  &:hover,
  &:active {
    opacity: 0.9;
  }
`

function handleOpenSupportChat() {
  comm100.openChatWindow()
}

export function Footer() {
  const i18n = useI18n()
  const location = ReactRouter.useLocation()
  const clientType = ReactRedux.useSelector(state =>
    configuration?.getClientType(state.configuration)
  )
  const isMobile = R.equals(ClientType.MOBILE_BROWSER, clientType)
  const authenticated = ReactRedux.useSelector(state =>
    Session.isAuthenticated(state.session)
  )
  const eSportQuery = useSafeUpdateQuery({ 'bt-path': '/e_sport/300' })
  const homeSportQuery = useSafeUpdateQuery({ 'bt-path': '/' })

  /**
   * Hides the footer:
   * - When playing any game on mobile
   * - You're on the Experts Area route
   */

  if (
    (isMobile &&
      ((location?.search?.includes('live-casino') && authenticated) ||
        location?.search?.includes('casino'))) ||
    location.pathname === '/sports'
  ) {
    return null
  }

  return (
    <Common.Box
      display="flex"
      backgroundColor="#0E161E"
      flexDirection="column"
      alignItems="center"
      as="footer"
      pb="40px"
      px="16px"
      maxWidth="1440px"
      margin="auto"
    >
      <Scrollable pt="75px" pb="72px">
        <PaymentProviderImages />
      </Scrollable>
      <Scrollable
        py="16px"
        borderTop="1px solid"
        borderBottom="1px solid"
        borderColor="hsla(0, 0%, 100%, 0.1)"
      >
        <GamesProviderImages />
      </Scrollable>
      <Common.Box
        display="grid"
        gridTemplateColumns={['1fr 1fr', null, '1fr 1fr 1fr 1fr']}
        pt={['24px', null, '70px']}
        pb="20px"
        gridRowGap="50px"
        color="#E4EBF2"
        fontFamily="Roboto"
        fontSize="15px"
        fontWeight="400"
        lineHeight="28px"
      >
        <Common.Box
          display="flex"
          borderRight="1px solid"
          justifyContent="flex-start"
          pl={['20px', '40px']}
          width="100%"
          borderColor="hsla(0, 0%, 100%, 0.1)"
        >
          <Common.Box
            display="flex"
            flexDirection="column"
            style={{ gap: '22px' }}
          >
            <Common.Box
              pb="9px"
              fontSize="21px"
              lineHeight="28px"
              fontWeight="600"
            >
              {i18n.translate('footer.spinlira')}
            </Common.Box>
            <LinkWrapper to="/about">
              {i18n.translate('footer.about-us')}
            </LinkWrapper>
            <LinkWrapper to="/faq">{i18n.translate('footer.faq')}</LinkWrapper>
            <LinkWrapper to="/terms-and-conditions">
              {i18n.translate('footer.terms-and-conditions')}
            </LinkWrapper>
            <LinkWrapper to="/privacy-policy">
              {i18n.translate('footer.privacy-policy')}
            </LinkWrapper>
          </Common.Box>
        </Common.Box>
        <Common.Box
          display="flex"
          justifyContent={['center', 'flex-start']}
          pl={['0px', '40px']}
          width="100%"
          borderRight="1px"
          borderStyle={[null, null, 'solid']}
          borderColor="hsla(0, 0%, 100%, 0.1)"
        >
          <Common.Box
            display="flex"
            flexDirection="column"
            style={{ gap: '22px' }}
          >
            <Common.Box
              pb="9px"
              fontSize="21px"
              lineHeight="28px"
              fontWeight="600"
            >
              {i18n.translate('footer.featured')}
            </Common.Box>
            <LinkWrapper to="/payments">
              {i18n.translate('footer.paymnets')}
            </LinkWrapper>
            <ExternalLinkWrapper
              href="https://smartaffpartners.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {i18n.translate('footer.affiliate')}
            </ExternalLinkWrapper>
            <LinkWrapper to="/responsible-gaming">
              {i18n.translate('footer.responsible-gaming')}
            </LinkWrapper>
          </Common.Box>
        </Common.Box>
        <Common.Box
          display="flex"
          justifyContent="flex-start"
          pl={['20px', '40px']}
          width="100%"
          borderRight="1px solid"
          borderColor="hsla(0, 0%, 100%, 0.1)"
        >
          <Common.Box
            display="flex"
            flexDirection="column"
            style={{ gap: '22px' }}
          >
            <Common.Box
              pb="9px"
              fontSize="21px"
              lineHeight="28px"
              fontWeight="600"
            >
              {i18n.translate('footer.games')}
            </Common.Box>
            <LinkWrapper to="/casino">
              {i18n.translate('footer.casino')}
            </LinkWrapper>
            <LinkWrapper to="/live-casino">
              {i18n.translate('footer.live-casino')}
            </LinkWrapper>
            <LinkWrapper to={`/sports?${homeSportQuery}`}>
              {i18n.translate('footer.sport')}
            </LinkWrapper>
            <LinkWrapper to={`/sports?${eSportQuery}`}>
              {i18n.translate('footer.e-sport')}
            </LinkWrapper>
          </Common.Box>
        </Common.Box>
        <Common.Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems={'flex-start'}
          pl={['20px', '40px']}
        >
          <Common.Box maxWidth="80%" mb="50px">
            <Common.Box
              pb="9px"
              fontSize="21px"
              lineHeight="28px"
              fontWeight="600"
            >
              {i18n.translate('footer.contact-us')}
            </Common.Box>
            <Common.Box maxWidth="233px" pb="18px" pt="22px">
              {i18n.translate('footer.contact-time')}
            </Common.Box>
            <Button onClick={handleOpenSupportChat}>
              <Icons.SupportChat />
              {i18n.translate('footer.live-chat')}
            </Button>
          </Common.Box>
          <Common.Box pl="16px" height="100%">
            <SocialMediaSection />
          </Common.Box>
        </Common.Box>
      </Common.Box>
      <Common.Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        my="20px"
        textAlign="center"
      >
        <AnjouanSeal />
        {i18n.hasTranslation('footer.legeal-text') && (
          <Common.Box color="#E4EBF2" mt="16px" maxWidth="800px">
            <HtmlContent
              html={{
                __html: i18n.translate('footer.legeal-text'),
              }}
            />
          </Common.Box>
        )}
      </Common.Box>
      <AppVersion />
    </Common.Box>
  )
}

// for @loadable/components
export default Footer
