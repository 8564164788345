import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as Urql from 'urql'
import PropTypes from 'prop-types'
import { gql } from 'urql'

import * as I18n from '@rushplay/i18n'
import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Icons from './icons'
import * as Session from './session'
import * as Player from './player'
import { Balance } from './balance'
import { Button } from './button'
import { Logotype } from './logotype'
import { ScrollLock } from './scroll-lock'
import { comm100 } from './support-chat-api'
import { useMenuQueries } from './use-menu-queries'
import { useSafeUpdateQuery } from './use-safe-update-query'

const getCasinoCategories = gql`
  query CasinoCategories {
    catalog(key: "main") {
      ... on GetCatalogSuccess {
        categories {
          id
          key
        }
      }
    }
  }
`

const getGameProviders = gql`
  query GameProviders {
    providers {
      name
      type
    }
  }
`

const getLiveCasinoGameProviders = gql`
  query GameProviders($isLiveCasino: Boolean!) {
    providers(isLiveCasino: $isLiveCasino) {
      name
      type
    }
  }
`

function encodeQuery(query) {
  return encodeURIComponent(JSON.stringify(query))
}

const Wrapper = styled.header`
  ${css({
    backgroundColor: '#142737',
    position: 'sticky',
    top: '0px',
    left: '0px',
    height: [null, null, '100vh'],
    width: ['100%', null, '375px'],
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    zIndex: [110, 100],
  })};

  flex-shrink: 0;

  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`

const MenuWrapper = styled(Common.Box)`
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`

const Overlay = styled.div`
  position: fixed;
  z-index: 109;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  transition: 0.5s;
`

function handleOpenChat() {
  comm100.openChatWindow()
}

function MenuSection(props) {
  const [sectionOpen, setSectionOpen] = React.useState(true)
  return (
    <Common.Box pt="8px">
      <Common.Box display="flex" alignItems="center" height="32px">
        <Common.Box
          fontSize="25px"
          pr="8px"
          style={{ cursor: 'pointer' }}
          onClick={() => setSectionOpen(prev => !prev)}
        >
          {sectionOpen ? <Icons.MenuIconMinus /> : <Icons.MenuIconPlus />}
        </Common.Box>
        <Common.Box color="#93B9E5">{props.title}</Common.Box>
      </Common.Box>
      <Common.Box
        pt="16px"
        display={sectionOpen ? 'grid' : 'none'}
        gridTemplateColumns="1fr 1fr"
        gridTemplateRows="44px 44px"
        style={{ columnGap: '16px', rowGap: '8px' }}
        fontSize="14px"
        fontWeight="700"
        lineHeight="18px"
        color="white"
      >
        {props.children}
      </Common.Box>
      <Common.Box
        my="16px"
        borderBottom="1px solid"
        borderColor="hsl(0, 0%, 100%, 0.1)"
      />
    </Common.Box>
  )
}

MenuSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
}

function CasinoMenuSection(props) {
  const [sectionOpen, setSectionOpen] = React.useState(true)
  const i18n = I18n.useI18n()
  return (
    <Common.Box pt="8px">
      <Common.Box display="flex" justifyContent="space-between" height="32px">
        <Common.Box display="flex" alignItems="center">
          <Common.Box
            fontSize="25px"
            pr="8px"
            style={{ cursor: 'pointer' }}
            onClick={() => setSectionOpen(prev => !prev)}
          >
            {sectionOpen ? <Icons.MenuIconMinus /> : <Icons.MenuIconPlus />}
          </Common.Box>
          <Common.Box color="#93B9E5">{props.title}</Common.Box>
        </Common.Box>
        <Common.Box
          as={ReactRouter.Link}
          to="/casino/1/discover"
          fontSize="14px"
          fontWeight="600"
          lineHeight="20px"
          px="10px"
          py="5px"
          backgroundColor="#0094FF"
          borderRadius="4px"
          color="white"
        >
          {i18n.translate('see-all')}
        </Common.Box>
      </Common.Box>
      <Common.Box
        pt="16px"
        display={sectionOpen ? 'grid' : 'none'}
        gridTemplateColumns="1fr 1fr"
        gridTemplateRows="44px 44px"
        style={{ columnGap: '16px', rowGap: '8px' }}
        fontSize="14px"
        fontWeight="700"
        lineHeight="18px"
        color="white"
      >
        {props.children}
      </Common.Box>
      <Common.Box
        my="16px"
        borderBottom="1px solid"
        borderColor="hsl(0, 0%, 100%, 0.1)"
      />
    </Common.Box>
  )
}

CasinoMenuSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
}

function ProviderMenuSection(props) {
  const [sectionOpen, setSectionOpen] = React.useState(true)
  const [providers_response] = Urql.useQuery({ query: getGameProviders })
  const [live_casino_providers_response] = Urql.useQuery({
    query: getLiveCasinoGameProviders,
    variables: {
      isLiveCasino: true,
    },
  })
  const i18n = I18n.useI18n()

  const providers = providers_response.data?.providers || []
  const liveProviders = live_casino_providers_response.data?.providers || []

  const midIndex = Math.floor(providers.length / 2)

  //inserting live providers in the middle of the list
  // TODO: ask BE team to add the possibility of getting both live and regular providers in the same list
  const allProviders = [
    ...providers.slice(0, midIndex + Math.ceil(liveProviders.length / 2)),
    ...liveProviders,
    ...providers.slice(midIndex + Math.ceil(liveProviders.length / 2)),
  ]
  const itemsNumber = allProviders.length
  const [currentColumn, setCurrentColumn] = React.useState(0)
  function handlePrevColumn() {
    setCurrentColumn(prevColumn => prevColumn - 1)
  }

  function handleNextColumn() {
    setCurrentColumn(prevColumn => prevColumn + 1)
  }
  const [width, setWidth] = React.useState(0)

  return (
    <Common.Box
      pt="8px"
      ref={el => {
        setWidth(el?.getBoundingClientRect()?.width)
      }}
    >
      <Common.Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height="32px"
      >
        <Common.Box display="flex" alignItems="center">
          <Common.Box
            fontSize="25px"
            pr="8px"
            style={{ cursor: 'pointer' }}
            onClick={() => setSectionOpen(prev => !prev)}
          >
            {sectionOpen ? <Icons.MenuIconMinus /> : <Icons.MenuIconPlus />}
          </Common.Box>
          <Common.Box color="#93B9E5">{props.title}</Common.Box>
        </Common.Box>
        <Common.Box display={sectionOpen ? 'flex' : 'none'} alignItems="center">
          <Common.Box
            fontSize="25px"
            pr="10px"
            color={currentColumn === 0 ? 'gray' : '#0094FF'}
            onClick={() => currentColumn > 0 && handlePrevColumn()}
            style={{ cursor: 'pointer' }}
          >
            <Icons.ChevronBtnLeft />
          </Common.Box>
          <Common.Box
            fontSize="25px"
            color={currentColumn === itemsNumber / 2 - 2 ? 'gray' : '#0094FF'}
            onClick={() =>
              currentColumn < itemsNumber / 2 - 2 && handleNextColumn()
            }
            style={{ cursor: 'pointer' }}
          >
            <Icons.ChevronBtnRight />
          </Common.Box>
        </Common.Box>
      </Common.Box>
      <Common.Box
        pt="16px"
        display={sectionOpen ? 'grid' : 'none'}
        gridTemplateColumns={`repeat(${Math.ceil(
          itemsNumber / 2
        )}, calc(50% - 8px))`}
        gridTemplateRows="repeat(2, 44px)"
        style={{
          columnGap: '16px',
          rowGap: '8px',
          transform: `translateX(-${currentColumn * (width / 2 - 8) +
            currentColumn * 16}px)`,
          transition: 'transform 0.3s ease-in-out',
        }}
        fontSize="14px"
        fontWeight="700"
        lineHeight="18px"
        color="white"
      >
        {allProviders.map(provider => (
        //   <Common.Box
        //     as={ReactRouter.Link}
        // {providers_response.data?.providers.map(provider => (
          <MenuItem
            key={provider.name}
            title={
              i18n.hasTranslation(
                `menu.${Herz.Utils.Strings.toKebabCase(provider.name)}`
              )
                ? i18n.translate(
                    `menu.${Herz.Utils.Strings.toKebabCase(provider.name)}`
                  )
                : provider.name
            }
            to={`/casino/search?q=${encodeQuery({
              [`${provider.type}s`]: provider.name,
            })}`}
            iconSrc={i18n.translate(
              `menu.${Herz.Utils.Strings.toKebabCase(provider.name)}.icon`
            )}
          />
        ))}
      </Common.Box>
      <Common.Box
        my="16px"
        borderBottom="1px solid"
        borderColor="hsl(0, 0%, 100%, 0.1)"
      />
    </Common.Box>
  )
}

ProviderMenuSection.propTypes = {
  title: PropTypes.string,
}

function MenuItem(props) {
  return (
    <Common.Box
      as={ReactRouter.Link}
      to={props.to}
      display="flex"
      backgroundColor="#13202C"
      alignItems="center"
      p="9px"
      borderRadius="4px"
    >
      {props?.iconSrc ? (
        <Common.Box
          as="img"
          alt={props.title}
          src={props.iconSrc}
          mr="8px"
          height="24px"
          width="24px"
        ></Common.Box>
      ) : null}
      {props.title}
    </Common.Box>
  )
}

MenuItem.propTypes = {
  iconSrc: PropTypes.string,
  title: PropTypes.string,
  to: PropTypes.string,
}

export function SideMenu() {
  const [menuOpen, setMenuOpen] = React.useState(false)
  const i18n = I18n.useI18n()
  const authenticated = Session.useAuthenticated()
  const { withdrawalQuery, loginQuery, registerQuery } = useMenuQueries()
  const [response] = Urql.useQuery({ query: getCasinoCategories })
  const username = ReactRedux.useSelector(state =>
    Player.getUsername(state.player)
  )
  const casinoCategories = response?.data?.catalog?.categories || []
  const topCasinoCategories = casinoCategories.slice(0, 4)
  const location = ReactRouter.useLocation()
  const liveBetsQuery = useSafeUpdateQuery({ 'bt-path': '/live' })
  const eSportQuery = useSafeUpdateQuery({ 'bt-path': '/e_sport/300' })
  const homeSportQuery = useSafeUpdateQuery({ 'bt-path': '/' })

  React.useEffect(() => {
    setMenuOpen(false)
  }, [location])

  React.useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 1000) {
        setMenuOpen(false)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <Wrapper>
      {menuOpen && <ScrollLock />}
      {menuOpen && <Overlay onClick={() => setMenuOpen(false)} />}
      <Common.Box
        backgroundColor="#142737"
        position="sticky"
        top="0px"
        left="0px"
        zIndex="200"
        px="8px"
        pt={['8px', null, '32px']}
        pb={['8px', null, '0px']}
        display="flex"
        justifyContent="space-between"
        flexDirection={['row', null, 'column']}
      >
        <Common.Flex alignItems="center">
          <Common.Box
            display={['block', null, 'none']}
            color="#92B9E5"
            fontSize="18px"
            pr="16px"
            onClick={() => setMenuOpen(prev => !prev)}
          >
            <Icons.Menu />
          </Common.Box>
          <Common.Box as={ReactRouter.Link} to="/">
            <Logotype />
          </Common.Box>
        </Common.Flex>

        {!authenticated ? (
          <Common.Box
            display="grid"
            gridTemplateColumns="1fr 1fr"
            gridGap={1}
            pt={['0px', null, '30px']}
            pb={['0px', null, '24px']}
          >
            <ReactRouter.Link to={`?${loginQuery}`}>
              <Button variant="secondary-outlined" stretch>
                {i18n.translate('main-menu.login')}
              </Button>
            </ReactRouter.Link>

            <ReactRouter.Link to={`?${registerQuery}`}>
              <Button variant="primary" stretch>
                {i18n.translate('main-menu.register')}
              </Button>
            </ReactRouter.Link>
          </Common.Box>
        ) : (
          <Common.Box
            display="flex"
            py={['0px', null, '8px']}
            alignItems="center"
            flexDirection={['row-reverse', null, 'row']}
          >
            <Common.Flex>
              <ReactRouter.Link to="/account">
                <Common.Box
                  as="img"
                  alt="user"
                  src="/images/user-icon.svg"
                  width="39px"
                  height="39px"
                />
              </ReactRouter.Link>
              <Common.Box pl="8px">
                <Common.Box
                  color="#93B9E5"
                  fontSize="12px"
                  fontWeight="600"
                  lineHeight="20px"
                >
                  {username}
                </Common.Box>
                <Balance />
              </Common.Box>
            </Common.Flex>
            <Common.Box
              display="grid"
              pl={['0px', null, '13px']}
              pr={['13px', null, '0px']}
              gridGap="24px"
              gridTemplateColumns="1fr 1fr 1fr"
              style={{ gap: '24px' }}
            >
              <Common.Box
                as="img"
                alt="live-casino"
                src={i18n.translate(`main-menu.cards.icon`)}
              ></Common.Box>
              <Common.Box
                as="img"
                alt="bonuses"
                src={i18n.translate(`main-menu.bonuses.icon`)}
              ></Common.Box>
              <Common.Box
                as="img"
                alt="notifications"
                src={i18n.translate(`main-menu.notifications.icon`)}
              ></Common.Box>
            </Common.Box>
          </Common.Box>
        )}
      </Common.Box>
      <MenuWrapper
        display={menuOpen ? 'block' : ['none', null, 'block']}
        zIndex="200"
        position={menuOpen ? 'fixed' : 'relative'}
        width={['100%', '375px']}
        top={['48px', null, '0px']}
        bottom="0px"
        left="0px"
        right="0px"
        backgroundColor="#142737"
        px="8px"
        pb="16px"
        pt={['16px', null, '0px']}
        overflowY="auto"
        overflowX="hidden"
      >
        <MenuSection title={i18n.translate('main-menu.quick-arrives')}>
          <Common.Box
            as={ReactRouter.Link}
            to="/casino"
            display="flex"
            alignItems="center"
            backgroundImage={`url(${i18n.translate(
              'main-menu.casino-item.image'
            )})`}
            backgroundSize="cover"
            p="9px"
            borderRadius="4px"
          >
            {i18n.translate('main-menu.casino')}
          </Common.Box>
          <Common.Box
            as={ReactRouter.Link}
            to="/live-casino"
            display="flex"
            alignItems="center"
            backgroundImage={`url(${i18n.translate(
              'main-menu.live-casino-item.image'
            )})`}
            backgroundSize="cover"
            p="9px"
            borderRadius="4px"
          >
            {i18n.translate('main-menu.live-casino')}
          </Common.Box>
          <Common.Box
            as={ReactRouter.Link}
            to="/sports"
            display="flex"
            alignItems="center"
            backgroundImage={`url(${i18n.translate(
              'main-menu.bets-item.image'
            )})`}
            backgroundSize="cover"
            p="9px"
            borderRadius="4px"
          >
            {i18n.translate('main-menu.bets')}
          </Common.Box>
          <Common.Box
            as={ReactRouter.Link}
            to="?promotions=campaigns"
            display="flex"
            alignItems="center"
            backgroundImage={`url(${i18n.translate(
              'main-menu.bonuses-item.image'
            )})`}
            backgroundSize="cover"
            p="9px"
            borderRadius="4px"
          >
            {i18n.translate('main-menu.bonuses')}
          </Common.Box>
        </MenuSection>
        <MenuSection title={i18n.translate('main-menu.finance')}>
          <MenuItem
            title={i18n.translate('main-menu.deposit')}
            iconSrc={i18n.translate(`main-menu.finance.deposit.icon`)}
            to={authenticated ? `?promotions=campaigns` : `?${loginQuery}`}
          />
          <MenuItem
            title={i18n.translate('main-menu.withdraw')}
            iconSrc={i18n.translate(`main-menu.finance.withdrawal.icon`)}
            to={authenticated ? `?${withdrawalQuery}` : `?${loginQuery}`}
          />
          <MenuItem
            title={i18n.translate('main-menu.my-account')}
            iconSrc={i18n.translate(`main-menu.finance.user.icon`)}
            to="/account"
          />
        </MenuSection>
        <CasinoMenuSection title={i18n.translate('main-menu.casino')}>
          {topCasinoCategories.map(category => (
            <MenuItem
              key={category.id}
              title={i18n.translate(category.key)}
              to={`/casino/${category.id}/${category.key}`}
              iconSrc={i18n.translate(`${category.key}.icon`)}
            />
          ))}
        </CasinoMenuSection>
        <ProviderMenuSection title={i18n.translate('main-menu.providers')} />
        <MenuSection title={i18n.translate('main-menu.sports')}>
          <MenuItem
            title={i18n.translate('main-menu.bets')}
            iconSrc={i18n.translate(`main-menu.sports.bets.icon`)}
            to={`/sports?${homeSportQuery}`}
          />
          <MenuItem
            title={i18n.translate('main-menu.live-bets')}
            iconSrc={i18n.translate(`main-menu.sports.live-bets.icon`)}
            to={`/sports?${liveBetsQuery}`}
          />
          <MenuItem
            title={i18n.translate('main-menu.e-sports')}
            iconSrc={i18n.translate(`main-menu.sports.e-sports.icon`)}
            to={`/sports?${eSportQuery}`}
          />
        </MenuSection>
        <MenuSection title={i18n.translate('main-menu.bonuses')}>
          <MenuItem
            title={i18n.translate('main-menu.my-bonuses')}
            iconSrc={i18n.translate(`main-menu.bonuses.my-bonuses.icon`)}
            to="?promotions=campaigns"
          />
          <MenuItem
            title={i18n.translate('main-menu.inventory')}
            iconSrc={i18n.translate(`main-menu.bonuses.inventory.icon`)}
            to={authenticated ? `?promotions=inventory` : `?${loginQuery}`}
          />
        </MenuSection>
        <MenuSection title={i18n.translate('main-menu.supports')}>
          <MenuItem
            title={i18n.translate('main-menu.provably-fair')}
            iconSrc={i18n.translate(`main-menu.supports.provably-fair.icon`)}
            to="/provably-fair"
          />
          <MenuItem
            title={i18n.translate('main-menu.terms-of-service')}
            iconSrc={i18n.translate(`main-menu.supports.terms-of-service.icon`)}
            to="/terms-and-conditions/general"
          />
          <Common.Box
            as="a"
            href="mailto:destek@spinlira.com"
            display="flex"
            backgroundColor="#13202C"
            alignItems="center"
            p="9px"
            borderRadius="4px"
          >
            <Common.Box
              as="img"
              alt="contact-us"
              src={i18n.translate(`main-menu.supports.contact-us.icon`)}
              pr="8px"
              height="24px"
              width="24px"
            />
            {i18n.translate('main-menu.contact-us')}
          </Common.Box>
          <Common.Box
            display="flex"
            backgroundColor="#13202C"
            alignItems="center"
            p="9px"
            borderRadius="4px"
            onClick={handleOpenChat}
          >
            <Common.Box
              as="img"
              alt="live-chat"
              src={i18n.translate(`main-menu.supports.live-chat.icon`)}
              pr="8px"
              height="24px"
              width="24px"
            />
            {i18n.translate('main-menu.live-chat')}
          </Common.Box>
        </MenuSection>
        {authenticated ? (
          <Common.Box
            display="grid"
            gridTemplateColumns="calc(50% - 8px)"
            gridTemplateRows="44px"
            pt="24px"
            pb="16px"
            borderBottom="1px solid"
            borderColor="hsl(0, 0%, 100%, 0.1)"
          >
            <MenuItem
              title={i18n.translate('main-menu.logout')}
              iconSrc={i18n.translate(`main-menu.logout.icon`)}
              to="/logout"
            />
          </Common.Box>
        ) : null}
      </MenuWrapper>
    </Wrapper>
  )
}

// for @loadable/component
export default SideMenu
