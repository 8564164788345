import * as ReactRedux from 'react-redux'
import * as React from 'react'

import * as Http from '../http-module'
import * as ServerConfiguration from '../server-configuration'
import { redirect } from '../http'

export function useLocaleRedirect() {
  const configuration = ServerConfiguration.useContext()
  const dispatch = ReactRedux.useDispatch()
  const basename = Http.useBasename()
  const url = Http.useUrl()

  const redirected = React.useMemo(() => {
    const nextBasename =
      configuration.locale.slug === 'en-TR' ||
      configuration.locale.slug === 'tr-TR'
        ? `/${configuration.locale.slug}`
        : '/tr-TR'
    const isLocaleIncorrect = basename !== nextBasename

    if (isLocaleIncorrect) {
      dispatch(redirect(`${nextBasename}${url}`))

      if (process.browser) {
        window.location.replace(`${nextBasename}${url}`)
      }

      return true
    }

    return false
  }, [basename, configuration, dispatch, url])

  return redirected
}
