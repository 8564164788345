import * as React from 'react'
import { Helmet } from 'react-helmet-async'

export function AnjouanSeal() {
  //TODO: get the seal id from BE

  React.useEffect(() => {
    if (window.anj_1253f32b_8ed1_404c_bfda_9da101f49982) {
      window?.anj_1253f32b_8ed1_404c_bfda_9da101f49982.init()
    }
  }, [])

  return (
    <React.Fragment>
      <Helmet>
        <script
          defer="defer"
          type="text/javascript"
          src="https://1253f32b-8ed1-404c-bfda-9da101f49982.snippet.anjouangaming.org/anj-seal.js"
        />
      </Helmet>
      <div
        id="anj-1253f32b-8ed1-404c-bfda-9da101f49982"
        data-anj-seal-id="1253f32b-8ed1-404c-bfda-9da101f49982"
        data-anj-image-size="50"
        data-anj-image-type="basic-small"
      ></div>
    </React.Fragment>
  )
}
