import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'

import * as I18n from '@rushplay/i18n'
import * as Common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import { Button } from './button'
import { HtmlContent } from './html-content'

const Wrapper = styled.div`
  flex: 1;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  max-width: 75%;

  ${css({
    justifyContent: ['center', null, 'flex-start'],
  })}
`

const Slide = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 1s ease-in-out;
  opacity: ${props => (props.active ? 1 : 0)};
`

const Dots = styled.div`
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
`

const Dot = styled.span`
  height: 4px;
  width: 24px;
  margin: 0 5px;
  background-color: ${props => (props.active ? '#fff' : '#CDCED0')};
  border-radius: 4px;
  display: inline-block;
  cursor: pointer;
`

const SlideTitle = styled.div`
  color: white;
  font-weight: 800;
  text-transform: uppercase;
  z-index: 2;

  ${css({
    fontSize: ['20px', null, '40px'],
  })}
`

function SlideContent(props) {
  const i18n = I18n.useI18n()

  return (
    <Slide active={props.active}>
      <Common.Box
        backgroundImage={`url(${props.translate(
          `promotions.${props.name}.img-src`
        )})`}
        backgroundRepeat="no-repeat"
        backgroundPosition={['center', 'top center, center']}
        backgroundSize={['cover']}
        mt={1}
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        position="relative"
        borderRadius="14px"
        height="100%"
      >
        <Common.Box
          display="flex"
          justifyContent={['space-between', null, 'flex-end']}
          alignItems={'flex-start'}
          flexDirection="column"
          position="relative"
          width="100%"
          px={[1, null, 3]}
          mb={1}
          //   mt={[1, '10px']}
          height="100%"
        >
          <Wrapper>
            <SlideTitle>
              {props.translate(`promotions.${props.name}.title`)}
            </SlideTitle>
            <Common.Box
              fontSize={['14px', null, '16px']}
              color="white"
              fontWeight="700"
              lineHeight="1.5"
              pb={['0px', null, 0]}
              maxWidth="75%"
            >
              <HtmlContent
                html={{
                  __html: props.translate(
                    `promotions.${props.name}.description`
                  ),
                }}
              />
            </Common.Box>
          </Wrapper>
          <Common.Box zIndex="2">
            <ReactRouter.Link to={props.link}>
              <Button fontSize={['14px', null, '18px']} variant="primary">
                {props.translate(`promotions.${props.name}.button-link`)}
              </Button>
            </ReactRouter.Link>
          </Common.Box>
          {i18n.hasTranslation(`promotions.${props.name}.img-src.mobile`) && (
            <Common.Box
              display={['block', 'none']}
              as="img"
              src={props.translate(`promotions.${props.name}.img-src.mobile`)}
              position="absolute"
              bottom="-15px"
              right="0px"
              height="260px"
              borderRadius="14px"
            />
          )}
          {i18n.hasTranslation(`promotions.${props.name}.img-src.overlay`) && (
            <Common.Box
              display={['none', 'block']}
              as="img"
              src={props.translate(`promotions.${props.name}.img-src.overlay`)}
              position="absolute"
              bottom="-16px"
              right="0px"
              height="260px"
              borderRadius="14px"
            />
          )}
        </Common.Box>
      </Common.Box>
    </Slide>
  )
}

SlideContent.propTypes = {
  name: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  link: PropTypes.string,
}

function bannersTranslationKeys(banners) {
  if (!banners || banners.length === 0) {
    return []
  }

  /**
      Image URL spinlira.promotions.${name}.img-src
      Mobile  spinlira.promotions.${name}.img-src.mobile
      Overlay spinlira.promotions.${name}.img-src.overlay
      Banner header spinlira.promotions.${name}.title
      Banner description  spinlira.promotions.${name}.description
      Logged in button text spinlira.promotions.${name}.button-link
      Terms and conditions  spinlira.promotions.${name}.terms-conditions
   */

  return banners.flatMap(banner => {
    return [
      `promotions.${banner.name}.img-src`,
      `promotions.${banner.name}.img-src.mobile`,
      `promotions.${banner.name}.img-src.overlay`,
      `promotions.${banner.name}.title`,
      `promotions.${banner.name}.description`,
      `promotions.${banner.name}.button-link`,
    ]
  })
}

bannersTranslationKeys.propTypes = {
  banners: PropTypes.array.isRequired,
}

export function BannerCarousel(props) {
  const i18n = I18n.useI18n()

  const [activeSlide, setActiveSlide] = React.useState(0)
  const intervalRef = React.useRef(null)
  const touchStartRef = React.useRef(null)
  const touchEndRef = React.useRef(null)

  function startSlideShow() {
    intervalRef.current = setInterval(() => {
      setActiveSlide(prev => {
        if (prev === props.banners.length - 1) {
          return 0
        }
        return (prev + 1) % props.banners.length
      })
    }, 5000)
  }

  React.useEffect(() => {
    if (props.banners?.length > 1) {
      startSlideShow()
      return function() {
        clearInterval(intervalRef.current)
      }
    }
  }, [props.banners])

  function handleDotClick(index) {
    setActiveSlide(index)
    clearInterval(intervalRef.current)
    startSlideShow()
  }

  function handleTouchStart(event) {
    touchStartRef.current = event.touches[0].clientX
  }

  function handleTouchMove(event) {
    touchEndRef.current = event.touches[0].clientX
  }

  function handleTouchEnd() {
    if (!touchStartRef.current || !touchEndRef.current) {
      return
    }

    const distance = touchStartRef.current - touchEndRef.current
    const threshold = 20 // Minimum distance to be considered a swipe

    if (Math.abs(distance) > threshold) {
      if (distance > 0) {
        // Swiped left
        setActiveSlide(prev =>
          prev === props.banners.length - 1 ? 0 : prev + 1
        )
      } else {
        // Swiped right
        setActiveSlide(prev =>
          prev === 0 ? props.banners.length - 1 : prev - 1
        )
      }
      clearInterval(intervalRef.current)
      startSlideShow()
    }

    touchStartRef.current = null
    touchEndRef.current = null
  }

  if (!props.banners || props.banners.length === 0) {
    return null
  }

  return (
    <Common.Box
      mx={1}
      mb={2}
      position="relative"
      height="260px"
      maxWidth="1440px"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {props.banners.map((banner, index) => (
        <SlideContent
          key={`key-${banner.name}`}
          name={banner.name}
          translate={i18n.translate}
          active={index === activeSlide}
          link={banner.loggedInButtonLink}
        />
      ))}
      {props.banners.length > 1 && (
        <Dots>
          {props.banners.map((banner, index) => (
            <Dot
              key={banner.name}
              active={index === activeSlide}
              onClick={() => handleDotClick(index)}
            />
          ))}
        </Dots>
      )}
    </Common.Box>
  )
}

BannerCarousel.propTypes = {
  banners: PropTypes.array.isRequired,
}
